<template>
    <div class="search-isin-body-filters">
        <div 
            class="si-filters-row si-filters-row-form" 
            v-bind:class="[ {'si-filters-row-error': block.has_errors}, {'si-filters-row-or': block.rule === 'OR'} ]"
            v-for="(block, index) in filters">
            <div class="si-filters-col-del">
                <el-popconfirm
                    confirmButtonText='OK'
                    cancelButtonText='Cancel'
                    hideIcon
                    title="Are you sure to delete this?"
                    @confirm="filters.splice(index, 1); count_results()">
                        <el-button slot="reference"
                            type="text"
                            class="form-delete-btn"
                            :disabled="filters.length < 2">
                            <i class="el-icon-circle-close"></i>
                        </el-button>
                </el-popconfirm>
            </div>

            <div class="si-filters-col-apply">
                <el-checkbox
                    v-model="block.apply"
                    @change="count_results"/>
            </div>

            <div class="si-filters-col-rule">
                <el-select
                    v-model="block.rule"
                    size="small"
                    @change="count_results"
                    :disabled="(index) ? false : true">
                    <el-option label="AND" value="AND"></el-option>
                    <el-option label="OR" value="OR"></el-option>
                </el-select>
            </div>

            <div class="si-filters-col-field">
                <el-select
                    v-model="block.field"
                    filterable
                    placeholder="Select field"
                    size="small"
                    @change="block.logics = undefined; block.value = null; block.value_list = [];count_results()">
                    <el-option
                        v-for="item in fields"
                        :key="item.orkl_name"
                        :label="item.app_name"
                        :value="item.orkl_name">
                    </el-option>
                </el-select>
            </div>

            <div class="si-filters-col-logics">
                <el-select
                    v-model="block.logics"
                    size="small"
                    @change="count_results">
                    <el-option
                        v-for="item in get_field_logics(block.field)"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
            </div>

            <div class="si-filters-col-value">
                <div v-if="value_field_type(block.field, block.logics) === 'common_input'">
                    <el-autocomplete
                        placeholder="value"
                        v-model="block.value"
                        size="small"
                        :fetch-suggestions="get_autocomplete_list"
                        @focus="autocomplete_field=block.field"
                        @select="count_results"
                        @change="count_results"/>
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'select_input'">
                    <el-select
                        v-model="block.value_list"
                        multiple
                        filterable
                        remote
                        :remote-method="get_autocomplete_options"
                        allow-create
                        default-first-option
                        placeholder="values"
                        size="small"
                        @focus="autocomplete_field=block.field"
                        @change="count_results">
                        <el-option
                            v-for="item in empty_options"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'number_input'">
                    <el-input
                        placeholder="value"
                        v-model="block.value"
                        size="small"
                        type="number"
                        @change="count_results"/>
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'range_number_input'" class="flex">
                    <el-input
                        placeholder="value from"
                        v-model="block.value_list[0]"
                        size="small"
                        type="number"
                        @change="count_results" />
                    <div class="si-filters-range-divider">&#8212;</div>
                    <el-input
                        placeholder="value to"
                        v-model="block.value_list[1]"
                        size="small"
                        type="number"
                        @change="count_results" />
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'date_input'">
                    <el-date-picker
                        v-model="block.value"
                        type="date"
                        placeholder="value"
                        size="small"
                        @change="count_results"
                        value-format="yyyy-MM-dd"/>
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'range_date_input'" class="flex">
                    <el-date-picker
                        v-model="block.value_list[0]"
                        type="date"
                        placeholder="value from"
                        size="small"
                        @change="count_results"
                        value-format="yyyy-MM-dd" />
                    <div class="si-filters-range-divider">&#8212;</div>
                    <el-date-picker
                        v-model="block.value_list[1]"
                        type="date"
                        placeholder="value to"
                        size="small"
                        @change="count_results"
                        value-format="yyyy-MM-dd" />
                </div>
                <div v-else-if="value_field_type(block.field, block.logics) === 'bool_input'">
                    <el-select
                        v-model="block.value"
                        placeholder="value"
                        size="small"
                        @change="count_results">
                        <el-option label="Y" value="true"></el-option>
                        <el-option label="N" value="false"></el-option>
                    </el-select>
                </div>
            </div>


            <div class="si-filters-col-empty_select">
                <el-select
                    v-model="block.empty"
                    size="small"
                    @change="count_results"
                    v-if="block.logics && block.logics !== 'IS EMPTY' && block.logics !== 'IS NOT EMPTY'">
                    <el-option label="Drop Empty" value="drop_empty"></el-option>
                    <el-option label="Include Empty" value="include_empty"></el-option>
                    </el-option>
                </el-select>
            </div>
            <div class="si-filters-col-result">
                <FormattedNumber :value="block.count_result" zeros/>
            </div>

        </div>
        <div class="si-filters-row si-filters-row-add">
            <el-button type="text" :disabled="filter_has_errors" @click="add_condition">
                <i class="el-icon-circle-plus-outline"></i>  add condition
            </el-button>
        </div>
    </div>
</template>

<script>
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    components: { FormattedNumber },
    props: {
        fields: {
            type: Array,
            required: true
        },
        selected_date: {
            required: true
        },
        selected_asset: {
            required: true
        }
    },

    data(){
        return {
            filters: [],
            filters_init_rule: {
                apply      : true,
                rule       : 'AND',
                field      : null,
                value      : null,
                value_list : [],
                logics     : null,
                empty      : 'drop_empty',
            },
            filter_has_errors  : true,
            result_qty         : 0,
            autocomplete_field : undefined,
            empty_options      : [],
            filter_date        : undefined,
        }
    },

    methods: {
        load_filters(filters){
            if (filters)
                this.filters = filters;
            else
                this.filters = [JSON.parse(JSON.stringify(this.filters_init_rule))]

            this.filter_date = this.$moment(this.selected_date).format('YYYY-MM-DD')
            if (filters) this.count_results();
        },

        get_field_logics(orkl_name){
            if (!orkl_name)
                return [];
            let field = this.fields.find(f => f.orkl_name === orkl_name)
            if (!field)
                return [];


            
            if (field.field_type === 'string')
                return [ "EQUAL", "NOT EQUAL", "LIKE", "NOT LIKE", "IN LIST", "IS EMPTY", "IS NOT EMPTY", ];
            else if (field.field_type === 'number' || field.field_type === 'float')
                return [
                    "EQUAL",  "NOT EQUAL",  "LESS", "LESS OR EQUAL", "GREATER", "GREATER OR EQUAL",  "IN RANGE", "IN LIST", "IS EMPTY", "IS NOT EMPTY",];
            else if (field.field_type === 'date')
                return [
                    "EQUAL",  "NOT EQUAL",  "LESS", "LESS OR EQUAL", "GREATER", "GREATER OR EQUAL",  "IN RANGE", "IN LIST", "IS EMPTY", "IS NOT EMPTY",];
            else if (field.field_type === 'boolean')
                return [
                    "EQUAL", "IS EMPTY",];
            else 
                console.log(field.field_type)

            return []
        },

        value_field_type(orkl_name, logics){
            if (!orkl_name || !logics)
                return '';

            let field = this.fields.find(f => f.orkl_name === orkl_name)
            if (!field)
                return '';

            if (["IN LIST"].includes(logics))
                return 'select_input';
            else if (field.field_type === 'string' && !["IS EMPTY", "IS NOT EMPTY"].includes(logics))
                return 'common_input';
            else if (['number','float'].includes(field.field_type) && !["IS EMPTY", "IS NOT EMPTY", "IN RANGE"].includes(logics))
                return 'number_input';
            else if (['number','float'].includes(field.field_type) && ["IN RANGE"].includes(logics))
                return 'range_number_input';
            else if (field.field_type === 'date' && !["IS EMPTY", "IS NOT EMPTY", "IN RANGE"].includes(logics))
                return 'date_input';
            else if (field.field_type === 'date' && ["IN RANGE"].includes(logics))
                return 'range_date_input';
            else if (field.field_type === 'boolean' && !["IS EMPTY"].includes(logics))
                return 'bool_input';

            return '';
        },

        get_autocomplete_list(query_string, cb){
            if (!query_string || query_string.length < 2) {
                cb([]);
                return;
            }

            if (!this.autocomplete_field) {
                cb([]);
                return;
            }

            cb([]);
            return;
        },

        get_autocomplete_options(query_string){
            this.empty_options = []

            if (!query_string || query_string.length < 2) {
                return;
            }

            if (!this.autocomplete_field) {
                return;
            }

            return;
        },

        add_condition(){
            let new_rule = JSON.parse(JSON.stringify(this.filters_init_rule));
            this.filters.push(new_rule)
            this.check_errors()
        },

        count_results(){
            this.check_errors();
            if (this.filter_has_errors) return;
            this.$emit('set-loading', true);
            
            let filters = JSON.parse(JSON.stringify(this.filters))
            filters = filters.filter(f => { return f.apply })
            filters.forEach(f => {
                delete f.has_errors;
                delete f.count_result;

                let field = this.fields.find(fld => fld.orkl_name === f.field)
                if (['number','float'].includes(field.field_type) && !["IS EMPTY", "IS NOT EMPTY", "IN RANGE"].includes(f.logics)){
                    f.value *= 1
                }
                else if (['number','float'].includes(field.field_type) && ["IN RANGE"].includes(f.logics)){
                    f.value_list.forEach(vl => {
                        vl *= 1
                    })
                }
            })

            let params = {
                date: this.$moment(this.filter_date).format('YYYY-MM-DD'),
                asset: this.selected_asset,
                filters: filters,
            }

            this.$store.dispatch('searchIsin/searchIsinCount', params).then((response) => {
                if (response) {
                    this.filters.forEach((f, idx) => {
                        if (f.apply) {
                            this.$set(f, 'count_result', response[idx])
                        }
                        else {
                            this.$set(f, 'count_result',0)
                        }
                        if (idx === filters.length - 1){
                            this.result_qty = response[idx]
                        }
                    })
                }
                else {
                    this.result_qty = 0
                }
                this.$emit('set-loading', false);
                this.$emit('set-filters', this.filters, this.filter_has_errors, this.result_qty)
            })
        },

        check_errors(){
            let has_errors = false;

            if (!this.filters.length){
                this.filter_has_errors = true;
                return;
            }

            this.filters.forEach(block => {
                this.$set(block, 'has_errors', false);

                if (block.field && block.logics) {
                    let field = this.fields.find(f => f.orkl_name === block.field)
                    if (!field) {
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                    else if (!block.logics) {
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                    else if (!block.value && !['IS EMPTY','IS NOT EMPTY','IN RANGE','IN LIST'].includes(block.logics)) { 
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                    else if (!block.value_list.length && ['IN RANGE','IN LIST'].includes(block.logics)) { 
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                    else if (block.value_list.length != 2 && ['IN RANGE'].includes(block.logics)) { 
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                    else if (field && field.field_type == 'date' && ['IN RANGE'].includes(block.logics) && Date.parse(block.value_list[0]) > Date.parse(block.value_list[1])) {
                        has_errors = true;
                        this.$set(block, 'has_errors', true);
                    }
                }
                else {
                    has_errors = true;
                    this.$set(block, 'has_errors', true);
                }

                // this.$set(block, 'count_result', 0);
            })

            this.filter_has_errors = has_errors
            
        },
    },

    watch: {
        selected_date(val){
            this.filter_date = val;
            this.count_results()
        },
    }
}
</script>
